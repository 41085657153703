import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { instance } from "../../middleware/instance";
import {
  schoolPayload,
  studentAdminBusPayload,
  subUsers,
} from "../../utils/interfaces";

// fetches the school profile
export const fetchSchProfile = async () => {
  return await instance({
    url: `school/adminProfile/`,
    method: "GET",
  });
};

// fetches the school profile
export const fetchSchSubUsers = async () => {
  return await instance({
    url: `school/listAllSubUsers`,
    method: "GET",
  });
};

// fetches the school profile
export const fetchSchBusDailyReport = async (
  page: number,
  limit: number,
  dateFilter?: string
) => {
  if (dateFilter !== "" && dateFilter !== "Invalid Date" && dateFilter) {
    return await instance({
      url: `school/listSchoolBusDailyReport?page=${page}&limit=${limit}&dateFilter=${dateFilter}`,
      method: "GET",
    });
  } else {
    return await instance({
      url: `school/listSchoolBusDailyReport?page=${page}&limit=${limit}`,
      method: "GET",
    });
  }
};

export const listSubAdminStudents = async (subAdminId: string) => {
  return await instance({
    url: `school/listStudentUnderadmin/${subAdminId}`,
    method: "GET",
  });
};

// custom hook to fetch school profile
export const useSchoolProfile = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    ["school-profile"],
    () => fetchSchProfile(),
    {
      onError: (error: any) => {
        // toast.error(error?.response?.data?.message, {
        //   toastId: "sch-profile-error",
        // });
      },
    }
  );
  const schoolProfile = data;
  return { schoolProfile, isLoading, isError, refetch };
};

// custom hook to fetch school profile
export const useSchoolBusDailyReport = (
  page: number,
  limit: number,
  dateFilter?: string
) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    ["school-bus", page, limit, dateFilter],
    () => fetchSchBusDailyReport(page, limit, dateFilter),
    {
      onError: (error: any) => {
        // toast.error(error?.response?.data?.message, {
        //   toastId: "bus-daily-error",
        // });
      },
    }
  );
  const schoolBusDaily = data;
  return { schoolBusDaily, isLoading, isError, isFetching, refetch };
};

export const useListSubAdminStudents = (subAdminId: string) => {
  const { data, isLoading, isError, refetch, isFetching } = useQuery(
    ["subadmin-students", subAdminId],
    () => listSubAdminStudents(subAdminId),
    {
      onSuccess: (data) => {
        // toast.success(data?.data?.message, { toastId: "subadmin-success" });
      },
      onError: (error: any) => {
        // toast.error(error?.response?.data?.message, {
        //   toastId: "subadmin-error",
        // });
      },
    }
  );
  const subAdminStudents = data;

  return { subAdminStudents, isLoading, isError, refetch, isFetching };
};

// custom hook to edit or  update parent record
export const useEditSchool = (id: any) => {
  const schoolPayload = useMutation(
    (schoolPayloadData: schoolPayload) => {
      return instance({
        url: `school/editSchoolDetails/${id}`,
        method: "PUT",
        data: schoolPayloadData,
      });
    },
    {
      onSuccess: (data) => {
        toast.success("School Profile edited successfully", {
          toastId: "edit-sch-success",
        });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.error, {
          toastId: "edit-school-error",
        });
      },
    }
  );
  return { schoolPayload };
};

// custom hook to edit or  update parent record
export const useCreateSchoolSubUsers = () => {
  const schoolSubUsers = useMutation(
    (subUsers: any) => {
      return instance({
        url: `school/subUsers`,
        method: "POST",
        data: subUsers,
      });
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message, { toastId: "create-sch-success" });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.details[0]?.message, {
          toastId: "create-sch-error",
        });
      },
    }
  );
  return { schoolSubUsers };
};

export const useEditSchoolSubUsers = (id: string) => {
  const schoolSubUsers = useMutation(
    (subUsers: any) => {
      return instance({
        url: `school/editSubUser/${id}`,
        method: "PUT",
        data: subUsers,
      });
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message, { toastId: "edit-sch-success" });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.details[0]?.message, {
          toastId: "edit-sch-error",
        });
      },
    }
  );
  return { schoolSubUsers };
};

// custom hook to assign student to bus admin
export const useAssignStudentToBusAdmin = () => {
  const schoolSubAdminStudents = useMutation(
    (subAdminStudents: studentAdminBusPayload) => {
      return instance({
        url: `school/assignStudentToSubAdmin`,
        method: "POST",
        data: subAdminStudents,
      });
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message, { toastId: "assign-success" });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message, {
          toastId: "assign-error",
        });
      },
    }
  );
  return { schoolSubAdminStudents };
};

export const useSchoolSubUsers = () => {
  const { data, isLoading, isError } = useQuery(
    ["school-sub-users"],
    () => fetchSchSubUsers(),
    {
      onError: (error: any) => {},
      keepPreviousData: true,
      staleTime: 100000,
    }
  );
  const schoolSubUsers = data;
  return { schoolSubUsers, isLoading, isError };
};

export const useDeleteSubUser = () => {
  const subUserDelete = useMutation(
    (id: any) => {
      return instance({
        url: `school/deleteSubUser/${id}`,
        method: "DELETE",
        data: id,
      });
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message, { toastId: "delete-user" });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.error, {
          toastId: "delete-user-error",
        });
      },
    }
  );
  return { subUserDelete };
};

// custom hook to unassign student to bus admin
export const useUnAssignStudentToBusAdmin = () => {
  const removeSubAdminStudents = useMutation(
    (data: { studentId: string; subAdminId: string }) => {
      return instance({
        url: `school/unassignStudentFromSubAdmin/${data.studentId}`,
        method: "POST",
        data: { subAdminId: data.subAdminId },
      });
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message, { toastId: "assign-success" });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message, {
          toastId: "assign-error",
        });
      },
    }
  );
  return { removeSubAdminStudents };
};

export const useSchoolBusTrack = () => {
  const schoolBusTrack = useMutation(
    (subAdminId: string | any) => {
      return instance({
        url: `school/schoolBusTrack`,
        method: "POST",
        data: { subAdminId: subAdminId },
      });
    },
    {
      onSuccess: (data) => {
        // toast.info(data?.data?.data?.message, { toastId: "track-success" });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message, {
          toastId: "track-error",
        });
      },
      retry: true,
    }
  );
  return { schoolBusTrack };
};
