export const parseJwt = (tok: any) => {
  if (tok !== null && tok !== undefined) {
    const base64Url = tok?.split(".")[1];
    const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const payload = JSON.parse(jsonPayload);
    const { name, id, schoolName } = payload;
    return [name, id, schoolName];
  }
};
