// ---------- import extenal dependencies ----------
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// ----------- import internal dependencies ---------
import { ReactComponent as Account } from "../../asset/img/svg/account2.svg";
import { ReactComponent as Audit } from "../../asset/img/svg/audit.svg";
import { ReactComponent as Down } from "../../asset/img/svg/chevron_down.svg";
import { ReactComponent as Class } from "../../asset/img/svg/class.svg";
import { ReactComponent as Dashboard } from "../../asset/img/svg/dashboard.svg";
import { ReactComponent as Student } from "../../asset/img/svg/education.svg";
import { ReactComponent as Exeat } from "../../asset/img/svg/exeat.svg";
import { ReactComponent as Plus } from "../../asset/img/svg/schbus.svg";
import { ReactComponent as Settings } from "../../asset/img/svg/settings.svg";
import TandaSecureApp from "../../asset/img/svg/tanda-secure-logo.svg";
import { ReactComponent as Ticket } from "../../asset/img/svg/ticket2.svg";
import { ReactComponent as Parent } from "../../asset/img/svg/user.svg";
import { useSchoolProfile } from "../../hooks/school-profile/schoolProfile";
import { baseUrl } from "../../middleware/instance";
import Button from "../Button";
import "./sidenav.scoped.css";

function SideNav(): JSX.Element {
  // ---------- invoke component hooks helper ---------
  const location = useLocation();
  const { schoolProfile } = useSchoolProfile();

  const role = schoolProfile?.data?.loggedInUserRole;

  const [subMenu, setSubmenu] = useState<any>({ open: false, name: "" });

  const { pathname } = location;
  const splitedPaths = pathname.split("/");

  const url: string | any = baseUrl();

  const handleRoutes = (baseUrl: string) => {
    if (
      baseUrl === "https://tanda-secure-school-app.herokuapp.com/api/v1/" ||
      baseUrl === "http://159.223.214.225:30000/api/v1/" ||
      baseUrl === "https://do.tandasecure.com/api/v1/"
    ) {
      return [
        {
          path: "/school/dashboard",
          icon: Dashboard,
          name: "dashboard",
          tag: "dashboard",
          visibleTo: [
            "School Admin",
            "Correspondent",
            "Support",
            "Auditor",
            "Hybrid",
          ],
        },
        {
          path: "/school/parent",
          icon: Parent,
          name: "parent / Guardian",
          tag: "parent",
          addFill: true,
          visibleTo: ["School Admin", "Correspondent", "Hybrid"],
        },
        {
          path: "/school/student",
          icon: Student,
          name: "student",
          tag: "student",
          addFill: true,
          visibleTo: ["School Admin", "Correspondent", "Hybrid"],
        },
        {
          path: "/school/employee",
          icon: Parent,
          name: "employee",
          tag: "employee",
          addFill: true,
          visibleTo: ["School Admin"],
        },
        {
          path: "/school/bus",
          icon: Plus,
          name: "school bus",
          tag: "bus",
          addFill: true,
          visibleTo: ["School Admin"],
        },
        {
          path: "/school/exeat/requests",
          icon: Exeat,
          name: "exeat",
          tag: "exeat",
          addFill: true,
          visibleTo: ["School Admin", "Hybrid", "Correspondent"],
          subMenu: true,
          subMenuIcon: (
            <Down
              className={`${
                subMenu.open === true && "flip"
              } absolute right-0 float-right`}
            />
          ),
          subMenuData: [
            {
              id: 1,
              title: "request",
              path: "/school/exeat/requests",
            },
            {
              id: 2,
              title: "house",
              path: "/school/exeat/house",
            },
          ],
        },
        {
          path: "/school/class",
          icon: Class,
          name: "class",
          tag: "class",
          visibleTo: ["School Admin", "Hybrid"],
        },
        {
          path: "/school/report/student-daily-report",
          icon: Audit,
          name: "report",
          tag: "report",
          visibleTo: ["School Admin"],
          subMenu: false,
          subMenuIcon: (
            <Down
              className={`${
                subMenu.open === true && "flip"
              } absolute right-0 float-right`}
            />
          ),
          subMenuData: [
            {
              id: 1,
              title: "students",
              path: "/school/report/student",
            },
            {
              id: 2,
              title: "employee",
              path: "/school/report/employee",
            },
            {
              id: 3,
              title: "exeat request",
              path: "/school/report/exeat/requests",
            },
          ],
        },
        {
          path: "/school/attendance_report/employees",
          icon: Ticket,
          name: "attencance report",
          tag: "attendance_report",
          addFill: true,
          visibleTo: ["School Admin", "Support", "Auditor", "Hybrid"],
        },
        {
          path: "/school/ticket",
          icon: Ticket,
          name: "ticket",
          tag: "ticket",
          addFill: true,
          visibleTo: ["School Admin", "Support", "Auditor", "Hybrid"],
        },
        {
          path: "/school/account",
          icon: Account,
          name: "account",
          tag: "account",
          visibleTo: ["School Admin"],
        },
        {
          path: "/school/settings",
          icon: Settings,
          name: "settings",
          tag: "settings",
          addFill: true,
          visibleTo: [
            "School Admin",
            "Correspondent",
            "Support",
            "Auditor",
            "Hybrid",
          ],
        },
      ];
    } else {
      return [
        {
          path: "/school/dashboard",
          icon: Dashboard,
          name: "dashboard",
          tag: "dashboard",
          visibleTo: [
            "School Admin",
            "Correspondent",
            "Support",
            "Auditor",
            "Hybrid",
          ],
        },
        {
          path: "/school/parent",
          icon: Parent,
          name: "parent / Guardian",
          tag: "parent",
          addFill: true,
          visibleTo: ["School Admin", "Correspondent", "Hybrid"],
        },
        {
          path: "/school/student",
          icon: Student,
          name: "student",
          tag: "student",
          addFill: true,
          visibleTo: ["School Admin", "Correspondent", "Hybrid"],
        },
        {
          path: "/school/employee",
          icon: Parent,
          name: "employee",
          tag: "employee",
          addFill: true,
          visibleTo: ["School Admin"],
        },
        {
          path: "/school/bus",
          icon: Plus,
          name: "school bus",
          tag: "bus",
          addFill: true,
          visibleTo: ["School Admin"],
        },
        {
          path: "/school/exeat/requests",
          icon: Exeat,
          name: "exeat",
          tag: "exeat",
          addFill: true,
          visibleTo: ["School Admin", "Hybrid", "Correspondent"],
          subMenu: true,
          subMenuIcon: (
            <Down
              className={`${
                subMenu.open === true && "flip"
              } absolute right-0 float-right`}
            />
          ),
          subMenuData: [
            {
              id: 1,
              title: "request",
              path: "/school/exeat/requests",
            },
            {
              id: 2,
              title: "house",
              path: "/school/exeat/house",
            },
          ],
        },
        {
          path: "/school/class",
          icon: Class,
          name: "class",
          tag: "class",
          visibleTo: ["School Admin", "Hybrid"],
        },
        {
          path: "/school/report/student-daily-report",
          icon: Ticket,
          name: "report",
          tag: "report",
          visibleTo: ["School Admin"],
        },
        {
          path: "/school/attendance_report/employees",
          icon: Ticket,
          name: "attencance report",
          tag: "attendance_report",
          addFill: true,
          visibleTo: ["School Admin", "Support", "Auditor", "Hybrid"],
        },
        {
          path: "/school/ticket",
          icon: Ticket,
          name: "ticket",
          tag: "ticket",
          addFill: true,
          visibleTo: ["School Admin", "Support", "Auditor", "Hybrid"],
        },
        {
          path: "/school/account",
          icon: Audit,
          name: "account",
          tag: "account",
          visibleTo: ["School Admin"],
        },
        {
          path: "/school/settings",
          icon: Settings,
          name: "settings",
          tag: "settings",
          addFill: true,
          visibleTo: [
            "School Admin",
            "Correspondent",
            "Support",
            "Auditor",
            "Hybrid",
          ],
        },
      ];
    }
  };

  return (
    <>
      <aside className="sidenav scrollY z-40 shadow-md">
        <nav>
          <div className="sidenav-brand flex flex-col space-y-2">
            <img src={TandaSecureApp} alt="Secure-school brand" />
            {/* <span className="">Secure School</span> */}
          </div>
          <ul className="sidenav-links font-InterRegular transition-all ease-in-out">
            {handleRoutes(url).map((item: any, i) => (
              <li
                key={i}
                className={`${
                  splitedPaths.includes(item.tag) ? "active-link" : ""
                } ${!item.visibleTo.includes(role) ? "hidden" : ""} relative`}
                onMouseOver={() => setSubmenu({ open: true, name: item.name })}
                onMouseLeave={() => setSubmenu({ open: false, name: "" })}
                data-test-id={item.name}
                id={item.name}
              >
                <Link to={item.path} className="flex flex-row items-start">
                  <item.icon
                    stroke={
                      !splitedPaths.includes(item.tag) ? "#818d8a" : "#19ba93"
                    }
                    fill={
                      item.addFill
                        ? `${
                            splitedPaths.includes(item.tag)
                              ? "#19ba93"
                              : "#818d8a"
                          }`
                        : "none"
                    }
                  />
                  <span className="font-InterRegular"> {item.name}</span>
                  <span>{item.subMenu && item.subMenuIcon}</span>
                </Link>

                {subMenu.open === true && subMenu.name === item.name ? (
                  <>
                    {item?.subMenu && (
                      <>
                        <li className={`cursor-pointer`} key={""}>
                          {item.subMenuData?.map((item: any, idx: any) => {
                            return (
                              <>
                                <Link
                                  to={`${item.path}`}
                                  className="flex flex-row items-center py-2"
                                  key={item.id + idx}
                                  data-test-id={item.title}
                                  id={item.title}
                                >
                                  <span className="font-InterRegular capitalize">
                                    {" "}
                                    {item.title}
                                  </span>{" "}
                                </Link>
                              </>
                            );
                          })}
                        </li>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
          <div className="advert-box">
            <img src="/asset/img/sec-schl.svg" alt="Advert" />
            <span>Place different types of adverts here!</span>
            <Button white addClass="mx-auto mt-2">
              Get started
            </Button>
          </div>
        </nav>
      </aside>
    </>
  );
}

export default SideNav;
