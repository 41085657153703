// ----------- import external dependencies -----------
import { lazy } from "react";
import { components } from "react-select";
// ----------- import pages with code splitting ----------
// import auth pages
const SignUp = lazy(() => import("../screens/auth/SignUp/signup"));
const SignIn = lazy(() => import("../screens/auth/Signin/signin"));
const ForgotPassword = lazy(
  () => import("../screens/auth/ForgotPassword/forgotPassword")
);
const NewPassword = lazy(
  () => import("../screens/auth/NewPassword/newpassword")
);
const Authentication = lazy(
  () => import("../screens/auth/Authentication/auth")
);
const AccountSettings = lazy(
  () => import("../screens/auth/AccountSetting/accountSetting")
);
// import school pages
const Dashboard = lazy(() => import("../screens/Dashboard/dashboard"));
const Parent = lazy(() => import("../screens/school/Parent/parent"));
const Ticket = lazy(() => import("../screens/school/tickets/tickets"));
const Student = lazy(() => import("../screens/school/student/student"));
const RegisterStudent = lazy(
  () => import("../screens/school/student/register-student/CreateStudent")
);
const EditStudent = lazy(
  () => import("../screens/school/student/register-student/CreateStudent")
);
const OverView = lazy(
  () => import("../screens/school/student/overview/Overview")
);
const ViewReport = lazy(
  () => import("../screens/school/student/view-report/ViewReport")
);
const StudentClass = lazy(() => import("../screens/school/class/StudentClass"));

const CreateClass = lazy(
  () => import("../screens/school/class/create-class/CreateClass")
);
const EditClass = lazy(
  () => import("../screens/school/class/edit-class/EditClass")
);
const ViewTicket = lazy(
  () => import("../screens/school/tickets/view-ticket/ViewTicket")
);
const AddNewTicket = lazy(
  () => import("../screens/school/tickets/add-new-ticket/AddNewTicket")
);
const ParentOverView = lazy(
  () => import("../screens/school/Parent/parent-overview/ParentOverView")
);
const RegisterParent = lazy(
  () => import("../screens/school/Parent/register-parent/CreateParent")
);
const RegisterEmployee = lazy(
  () => import("../screens/school/employee/register-employee/RegisterEmployee")
);
const EditEmployee = lazy(
  () => import("../screens/school/employee/register-employee/EditEmployee")
);
const EditParent = lazy(
  () => import("../screens/school/Parent/edit-parent/EditParent")
);
const EditSecondParent = lazy(
  () => import("../screens/school/Parent/edit-parent/EditSecondParent")
);
const RegisterAnotherParent = lazy(
  () => import("../screens/school/Parent/register-parent/RegisterAnotherParent")
);
const Invoice = lazy(
  () => import("../screens/school/account/invoice-overview/invoice")
);
const Users = lazy(() => import("../screens/school/Settings/users/users"));
const Roles = lazy(() => import("../screens/school/Settings/roles/roles"));
const SchoolProfile = lazy(
  () => import("../screens/school/Settings/sch-profile/schProfile")
);
const Profile = lazy(
  () => import("../screens/school/Settings/profile/profile")
);
const Notifications = lazy(
  () => import("../screens/school/Settings/notifications/notification")
);
const OpenHours = lazy(
  () => import("../screens/school/Settings/open-hours/openHrs")
);
const DailyReport = lazy(() => import("../screens/school/report/daily/daily"));
const EmployeeDailyReport = lazy(
  () => import("../screens/school/report/daily/employeeDaily")
);
const AccumulatedReport = lazy(
  () => import("../screens/school/report/accumulated/accumulated")
);
const EmployeeAccumulatedReport = lazy(
  () => import("../screens/school/report/accumulated/employeeAccumulated")
);
const Advertisement = lazy(
  () => import("../screens/school/account/advertisement/advert")
);
const Subscription = lazy(
  () => import("../screens/school/account/subscription /subscription")
);
const InvoicePaid = lazy(
  () => import("../screens/school/account/invoice-overview/invoicePaid")
);
const Employee = lazy(() => import("../screens/school/employee/employee"));
const EmployeeOverview = lazy(
  () => import("../screens/school/employee/employee-overview/EmployeeOverview")
);
const Bus = lazy(() => import("../screens/school/bus/Bus"));
const BusAdmin = lazy(() => import("../screens/school/bus/BusAdmin"));
const ExeatRequests = lazy(
  () => import("../screens/school/exeat/requests/Requests")
);
const ExeatHouse = lazy(() => import("../screens/school/exeat/house/House"));
const ExeatHouseAdmin = lazy(
  () => import("../screens/school/exeat/house/overview/HouseOverview")
);
const RegisterExeatHouse = lazy(
  () => import("../screens/school/exeat/house/house-forms/AddHouse")
);
const RegisterExeatStudent = lazy(
  () => import("../screens/school/exeat/house/house-forms/AddStudents")
);
const ExeatRequestReport = lazy(
  () => import("../screens/school/report/exeat-request/ExeatRequest")
);
const ExeatReportOverview = lazy(
  () =>
    import(
      "../screens/school/report/exeat-request/exeat-request-report/ExeatReportOverview"
    )
);
const BusDailyReport = lazy(
  () => import("../screens/school/report/daily/busDaily")
);
const AuditTrail = lazy(
  () => import("../screens/school/account/audit-trail/AuditTrail")
);
const AddUser = lazy(() => import("../screens/school/Settings/users/addUser"));
const Guardian = lazy(() => import("../screens/school/guardian/Guardian"));
const BusTracker = lazy(() => import("../screens/school/bus/Bustracker"));
const NotificationPage = lazy(
  () => import("../screens/school/notification/Notification")
);
const Panic = lazy(() => import("../screens/school/Settings/panic/Panic"));
const EmployeeAttendanceReport = lazy(
  () =>
    import("../screens/school/attendance_report/employee/EmployeeAttendance")
);
const StudentAttendanceReport = lazy(
  () =>
    import("../screens/school/attendance_report/student/StudentAttenceReport")
);

// ------------ auth routes ------------
export const authRoutes = [
  {
    path: "signup",
    component: SignUp,
  },
  {
    path: "login",
    component: SignIn,
  },
  {
    path: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "new-password",
    component: NewPassword,
  },
  {
    path: "auth-verification",
    component: Authentication,
  },
  {
    path: "account-setting",
    component: AccountSettings,
  },
];

// ------------ school routes ----------
export const schoolRoutes = [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "parent",
    component: Parent,
  },
  {
    path: "employee",
    component: Employee,
  },
  { path: "guardians/requests", component: Guardian },
  { path: "bus", component: Bus },
  {
    path: "settings",
    component: Users,
  },
  {
    path: "settings/users",
    component: Users,
  },
  {
    path: "settings/roles",
    component: Roles,
  },
  {
    path: "settings/school-profile",
    component: SchoolProfile,
  },
  {
    path: "settings/profile",
    component: Profile,
  },
  {
    path: "settings/notifications",
    component: Notifications,
  },
  {
    path: "settings/open-hours",
    component: OpenHours,
  },
  {
    path: "settings/panic",
    component: Panic,
  },
  { path: "attendance_report/employees", component: EmployeeAttendanceReport },
  { path: "attendance_report/students", component: StudentAttendanceReport },
  {
    path: "report",
    component: DailyReport,
  },
  {
    path: "report/student-daily-report",
    component: DailyReport,
  },
  {
    path: "report/employee-daily-report",
    component: EmployeeDailyReport,
  },
  {
    path: "report/daily-report",
    component: DailyReport,
  },
  { path: "report/bus-daily-report", component: BusDailyReport },
  {
    path: "report/student/daily-report",
    component: DailyReport,
  },
  {
    path: "report/employee-daily-report",
    component: EmployeeDailyReport,
  },
  {
    path: "report/student-accumulated-report",
    component: AccumulatedReport,
  },
  {
    path: "report/student-accumulated-report",
    component: AccumulatedReport,
  },
  {
    path: "report/employee-accumulated-report",
    component: EmployeeAccumulatedReport,
  },
  {
    path: "ticket",
    component: Ticket,
  },
  {
    path: "account",
    component: Subscription,
  },
  {
    path: "notification",
    component: NotificationPage,
  },
  {
    path: "account/audit-trail",
    component: AuditTrail,
  },
  {
    path: "account/advertisement",
    component: Advertisement,
  },
  {
    path: "account/subscription",
    component: Subscription,
  },
  { path: "invoice-overview/:id", component: Invoice },
  { path: "invoice/:id", component: InvoicePaid },
  {
    path: "student",
    component: Student,
  },
  {
    path: "edit-student/:id",
    component: EditStudent,
  },
  {
    path: "register-student",
    component: RegisterStudent,
  },
  {
    path: "over-view/:id",
    component: OverView,
  },
  {
    path: "view-report",
    component: ViewReport,
  },
  {
    path: "class",
    component: StudentClass,
  },
  {
    path: "create-class",
    component: CreateClass,
  },
  { path: "edit-class/:id", component: EditClass },
  {
    path: "view-ticket/:id",
    component: ViewTicket,
  },
  {
    path: "add-new-ticket",
    component: AddNewTicket,
  },
  { path: "parent-overview/:id", component: ParentOverView },
  { path: "employee-overview/:id", component: EmployeeOverview },
  { path: "register-parent", component: RegisterParent },
  { path: "register-employee", component: RegisterEmployee },
  { path: "edit-employee/:id", component: EditEmployee },
  { path: "edit-parent/parent1/:id", component: EditParent },
  { path: "edit-parent/parent2/:id", component: EditSecondParent },
  { path: "register-another-parent/:id", component: RegisterAnotherParent },
  { path: "bus/bus-admin/:id", component: BusAdmin },
  { path: "exeat/requests", component: ExeatRequests },
  { path: "report/exeat/requests", component: ExeatRequestReport },
  { path: "exeat/house", component: ExeatHouse },
  { path: "exeat/house/house-admin/:id", component: ExeatHouseAdmin },
  { path: "exeat/house/create-house", component: RegisterExeatHouse },
  { path: "exeat/house/add-student", component: RegisterExeatStudent },
  {
    path: "report/exeat/requests/overview/:id",
    component: ExeatReportOverview,
  },
  {
    path: "exeat/requests/overview/:id",
    component: ExeatReportOverview,
  },
  { path: "add-user", component: AddUser },
  { path: "bus/bus-admin/bus_track/:id", component: BusTracker },
];