// ------------- import external dependencies ------------
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// ---------- import external dependencies ------------
import { authRoutes } from "./routes";

function AuthRoutes() {
  const token = window.localStorage.getItem("authToken");

  if (token) {
    // return <Navigate to="/school" />;
  }

  return (
    <>
      <Routes>
        {authRoutes.map((route, ind) => (
          <Route key={ind} path={route.path} element={<route.component />} />
        ))}
      </Routes>
    </>
  );
}

export default AuthRoutes;
