// ------------- import external dependencies -----------
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// --------- import internal dependencies ----------
import DashboardLayout from "../Layouts/DashboardLayout";
import { schoolRoutes } from "./routes";

function SchoolRoute() {
  // ----------- intialize component custom hooks -----------
  const location = useLocation();

  const activePath: string = location.pathname.split("/")[2];
  const token = window.localStorage.getItem("authToken");
  const state = { from: location.pathname };

  // liist of available path
  const pathList: { [k: string]: string } = {
    dashboard: "Dashboard",
    parent: "Parent/Guardian",
    student: "Student",
    employee: "Employee",
    bus: "School Bus",
    report: "Report",
    ticket: "Ticket",
    settings: "Settings",
    account: "Account",
    class: "Class",
    exeat: "Exeat | Sublevel",
    house: "Exeat | House",
    attendance_report: "Attendance Report",
  };

  if (!token) {
    return <Navigate to="/login" state={state} />;
  }

  return (
    <>
      <DashboardLayout page={pathList[activePath]}>
        <Routes>
          <Route path="/" element={<Navigate to="dashboard" />} />
          {schoolRoutes.map((route, ind) => (
            <Route key={ind} path={route.path} element={<route.component />} />
          ))}
        </Routes>
      </DashboardLayout>
    </>
  );
}

export default SchoolRoute;
