import axios from "axios";
import { toast } from "react-toastify";

let hostUrl = window.location.hostname;
// https://notable-sonnnie-7178.apps.resilis.net

export const baseUrl = () => {
  const baseApi = hostUrl.includes("localhost")
    ? process.env.REACT_APP_STAGING_URL
    : hostUrl.includes("netlify")
    ? process.env.REACT_APP_STAGING_URL
    : hostUrl.includes("tandasecure")
    ? process.env.REACT_APP_LIVE_URL
    : "";
  return baseApi;
};

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const instance = axios.create({
  baseURL: baseUrl(),
});

instance.interceptors.request.use(
  function (config: any) {
    const token: string = localStorage.getItem("authToken") ?? "";
    config.headers["secretKey"] = SECRET_KEY;
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("authToken");
      localStorage.clear();
      window.location.replace(`/login`);
      toast.info("Session expired");
    }
    return Promise.reject(error);
  }
);
