// ----------- import external dependencies ----------
import React from "react";

type button = {
  children: React.ReactNode;
  type?: "submit" | "button";
  click?: () => void;
  white: boolean;
  full?: boolean;
  addClass?: string
};

function Button({
  children,
  click,
  type = "button",
  white = false,
  full = false,
  addClass,
  ...rest
}: button) {

  const addedClass = addClass?.split(' ');


  return (
    <>
      <button
        onClick={click}
        type={type}
        className={[
          !white
            ? "bg-regularGreen text-regularWhite"
            : "bg-regularWhite text-regularGreen",
            full ? "w-full" : "",
          "btn",
          "block",
          ...addedClass ?? []
        ].join(" ")}
        {...rest}
      >
        {children}
      </button>
    </>
  );
}

export default Button;
