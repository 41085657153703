// ---------- import external dependencies ----------
import { useMutation } from "react-query";
// --------- import inernal dependencies ---------
import { toast } from "react-toastify";
import { instance } from "../../middleware/instance";

type schoolDataObject = {
  name: string;
  email: string;
  role?: string;
  password: string;
  schoolName: string;
  schoolPhoneNumber: string;
  address: string;
  state: string;
  LGA: string;
};

type loginData = {
  email: string;
  password: string;
};

function useSchoolAuth() {
  /**
   * Handle school data registration
   */
  const schoolRegistration = useMutation((schoolData: schoolDataObject) => {
    return instance({
      url: "/school/register",
      method: "post",
      data: schoolData,
    });
  });

  /**
   * Handle login
   */
  const login = useMutation(
    (loginData: loginData) => {
      return instance({
        url: `/school/login`,
        method: "post",
        data: {
          email: loginData.email,
          password: loginData.password,
        },
      });
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message, { toastId: "login-success" });
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message, {
          toastId: "login-error",
        });
      },
    }
  );

  /**
   * Handle school email registration
   */
  const emailRegistration = useMutation((regData: { email: string }) => {
    return instance({
      url: "/school/registerSchoolEmail",
      method: "post",
      data: { email: regData.email },
    });
  });

  /**
   * Handle schoole otp verification sent to email
   */
  const verifySchoolOtp = useMutation(
    (verifyData: { email: string; otp: string }) => {
      return instance({
        url: "/school/verifyOtp",
        method: "post",
        data: { email: verifyData.email, otp: verifyData.otp },
      });
    }
  );

  /**
   * Handle user fortgot password
   */
  const forgotPassword = useMutation((userData: { email: string }) => {
    return instance({
      url: "/school/forgotPassword",
      method: "post",
      data: { email: userData.email },
    });
  });

  /**
   * Handle user fortgot password
   */
  const changePassword = useMutation(
    (userData: { email: string; newPassword: string; otp: string }) => {
      return instance({
        url: "/school/changeSchoolPassword",
        method: "put",
        data: {
          email: userData.email,
          newPassword: userData.newPassword,
          otp: userData.otp,
        },
      });
    }
  );

  /**
   * Clear local storage for auth keys
   */
  const logout = () => {
    localStorage.clear();
  };

  return {
    schoolRegistration,
    login,
    emailRegistration,
    verifySchoolOtp,
    forgotPassword,
    changePassword,
    logout,
  }
}

export default useSchoolAuth;
