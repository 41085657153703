/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BellOutlined,
  DownOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Badge, Dropdown, Menu, Spin } from "antd";
// import "antd/dist/antd.css";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import { BsThreeDotsVertical } from "react-icons/bs";
import { QueryClient, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminDp from "../../asset/img/imgs/adminDP.png";
import {
  fetchSchNotification,
  useSchoolNotification,
} from "../../hooks/header-hooks/notification";
import { useSchoolProfile } from "../../hooks/school-profile/schoolProfile";
import useSchoolAuth from "../../hooks/useAuth";
import { parseJwt } from "../../utils/decodeToken";
import "./header.scoped.css";

type headerProps = {
  heading: string;
};

function Header({ heading }: headerProps) {
  const queryClient = new QueryClient();

  const { logout } = useSchoolAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);

  const { data, isLoading } = useQuery(
    ["notifications", page],
    () => fetchSchNotification(page, "notification"),
    {
      keepPreviousData: true,
      staleTime: 1000000,
      getPreviousPageParam: (firstPage) => {
        return firstPage;
      },
      getNextPageParam: (lastPage) => {
        return lastPage;
      },
    }
  );
  const count = data?.data?.count;
  const nextPage = data?.data?.nextPage;

  const { schoolProfile } = useSchoolProfile();
  const schoolName = schoolProfile?.data?.data?.schoolName;
  const profilePictureUrl = schoolProfile?.data?.data?.profilePictureUrl;

  const scrollAreaRef: any = useRef();
  const lastNotification: any = useCallback(
    (node: any) => {
      if (isLoading) {
        return;
      }
      if (scrollAreaRef?.current) {
        scrollAreaRef?.current?.disconnect();
      }
      scrollAreaRef.current = new IntersectionObserver((enteries: any) => {
        if (enteries[0]?.isIntersecting && nextPage === true) {
          setPage((prev) => prev + 1);
        } else if (enteries[0]?.isIntersecting && nextPage === false) {
          setResults(data?.data?.data);
        }
      });
      if (node) {
        scrollAreaRef?.current?.observe(node);
      }
    },
    [isLoading]
  );

  const token = window.localStorage.getItem("authToken");
  const [jwtArray, setJwtArray] = useState<any>([]);
  const [results, setResults] = useState<any>([]);
  const [counter, setCounter] = useState<any>(count);
  const [showBadge, setShowBadge] = useState(true);

  useEffect(() => {
    if (data?.data?.nextPage) {
      queryClient.fetchQuery(["notifications", page + 1], () =>
        fetchSchNotification(page + 1, "notification")
      );
    }
    setResults(data?.data?.data);
    setCounter(count);
  }, [page, queryClient, data]);

  const logoutUser = () => {
    logout();
    navigate("/login", { replace: true });
  };

  React.useEffect(() => {
    if (token === null) {
      toast.warn("expired token");
      // navigate("/login", { replace: true });
    }

    const jwtPayload = parseJwt(token);
    setJwtArray(jwtPayload);
  }, [token, navigate]);

  function userMenu() {
    return (
      <Menu
        id="logout"
        style={{ height: "50px" }}
        className="flex cursor-pointer flex-row items-center justify-start"
      >
        <Menu.Item key={0}>
          <span
            className="flex flex-row items-center font-InterRegular"
            onClick={logoutUser}
            id="logout-action"
            data-test-id="logoutUser"
          >
            <PoweroffOutlined /> &nbsp; Logout
          </span>
        </Menu.Item>
      </Menu>
    );
  }

  console.log(heading, "heading");

  return (
    <>
      <div className="header-wrapper fixed top-0 z-40 shadow-md">
        <div className="header-left-section">
          <h1 className="font-InterBold text-xl font-bold text-offBlack">
            {heading}
          </h1>
          {/* <div className="global-search">
            <span className="material-icons-outlined text-xl text-offGrey">
              search
            </span>
            <input
              className="font-InterRegular"
              type="search"
              placeholder="Search something here..."
            />
          </div> */}
        </div>
        <div className="header-right-section space-x-4">
          {/* <Dropdown
            overlay={() => {
              return (
                <Spin spinning={isLoading}>
                  <div
                    className="recent-notification mt-3 rounded-lg bg-regularWhite pt-3 shadow-lg"
                    style={{ maxHeight: "400px", zIndex: "1000" }}
                  >
                    <div className="max-h-96 w-96 overflow-y-scroll">
                      {results?.map((i: any, index: any) => {
                        const word = i.message;
                        const getFirstLetter = word.charAt(0);
                        const createdAt: any = i.createdAt;
                        const wordSplit = word.split(" ");
                        const m = wordSplit.splice(-3, 3);
                        if (results?.length === index + 1) {
                          return (
                            <div
                              className="flex w-full cursor-pointer flex-row items-center justify-evenly space-x-4 rounded-sm px-2 pb-3 font-secureSchool opacity-100"
                              key={index}
                              ref={lastNotification}
                            >
                              <div
                                className="mt-1 flex cursor-pointer flex-row items-center justify-center rounded-full bg-offGrey p-5 text-center opacity-100"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "100%",
                                }}
                              >
                                <span className="font-InterExtraBold text-lg font-extrabold tracking-normal text-offBlack opacity-100">
                                  {getFirstLetter}
                                </span>
                              </div>
                              <div className="mt-2 flex flex-col items-start">
                                <span className="text-left font-InterBold text-sm font-bold tracking-normal text-offBlack opacity-100">
                                  {wordSplit.join().replace(/,/g, " ")}
                                </span>
                                <span className="text-left font-InterRegular text-xs tracking-normal text-offGrey opacity-100">
                                  {dayjs(createdAt).format("YYYY-MM-DD h:mm A")}
                                </span>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="flex w-full cursor-pointer flex-row items-center justify-evenly space-x-4 rounded-sm px-2 pb-3 font-secureSchool opacity-100"
                              key={index}
                            >
                              <div
                                className="mt-1 flex cursor-pointer flex-row items-center justify-center rounded-full bg-offGrey text-center opacity-100"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                }}
                              >
                                <span className="font-InterExtraBold text-lg font-extrabold tracking-normal text-offBlack opacity-100">
                                  {getFirstLetter}
                                </span>
                              </div>
                              <div className="mt-2 flex flex-col items-start">
                                <span className="text-left font-InterBold text-sm font-bold tracking-normal text-offBlack opacity-100">
                                  {wordSplit.join().replace(/,/g, " ")}
                                </span>
                                <span className="text-left font-InterRegular text-xs tracking-normal text-offGrey opacity-100">
                                  {dayjs(createdAt).format("YYYY-MM-DD h:mm A")}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </Spin>
              );
            }}
            placement="bottom"
            overlayClassName="drop cursor-pointer"
            overlayStyle={{ borderRadius: "30px" }}
            trigger={["hover", "click"]}
            onOpenChange={(open: boolean) => {
              if (open === true) {
                setCounter(0);
                setShowBadge(false);
              } else {
                setCounter(0);
              }
            }}
          >
            <div className="app-notification">
              <div className="">
                {showBadge ? (
                  <Badge count={counter} showZero>
                    <BellOutlined
                      style={{ fontSize: "23px" }}
                      className="cursor-pointer text-base text-offBlack"
                    />
                  </Badge>
                ) : (
                  <>
                    <BellOutlined
                      style={{ fontSize: "23px" }}
                      className="cursor-pointer text-base text-offBlack"
                    />
                  </>
                )}
              </div>
            </div>
          </Dropdown> */}
          <Badge dot>
            <BellOutlined
              style={{ fontSize: "23px" }}
              className="cursor-pointer text-base text-offBlack"
              onClick={() => navigate("/school/notification")}
              data-test-id="notification"
            />
          </Badge>
          <Dropdown overlay={userMenu}>
            <div className="flex items-center space-x-2 font-InterBold">
              {profilePictureUrl ? (
                <>
                  <img
                    src={`${profilePictureUrl}`}
                    alt="sch_logo"
                    className="profileImg cursor-pointer"
                    data-test-id="sch_profile"
                  />
                </>
              ) : (
                <img
                  src={AdminDp}
                  alt="sch_logo"
                  className="profileImg cursor-pointer"
                  data-test-id="sch_profile"
                />
              )}
              <span className="text-secureSchool cursor-pointer font-medium text-offBlack">
                {schoolName !== undefined ? schoolName : jwtArray[2]}
              </span>
              <DownOutlined style={{ fontSize: "14px" }} />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export default Header;
