// ---------- import external depemdencies ----------
import React from "react";

// --------- import internal dependencies -----------
import { Navigate } from "react-router-dom";
import Header from "../../components/Header";
import SideNav from "../../components/SideNav";
import "./dashboardlayout.scoped.css";

type Props = {
  page: string;
  children: React.ReactNode;
};
const token: string = localStorage.getItem("authToken") ?? "";

if (!token) {
  <Navigate to={"/login"} replace={true} />;
}

function DashboardLayout({ page, children }: Props) {
  return (
    <>
      <main className="relative overflow-hidden">
        <SideNav />
        <section className="main-content relative">
          <Header heading={page} />
          {children}
        </section>
      </main>
    </>
  );
}

export default DashboardLayout;
