import { Spin } from "antd";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Toaster from "./components/Notifier";
// import route wrappers
import "maplibre-gl/dist/maplibre-gl.css";
import AuthRoutes from "./routes/AuthRoutes";
import SchoolRoute from "./routes/SchoolRoute";

// ----------- import pages with code splitting ----------

const queryClient: any = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: "tracked",
    },
  },
});

function App() {
  return (
    <div id="app" role={"application"}>
      <QueryClientProvider client={queryClient}>
        <Suspense
          fallback={
            <div className="absolute top-1/2 left-1/2 translate-x-1/2 translate-y-1/2">
              <div className="">
                <Spin size="large" tip={"Loading"} />
              </div>
            </div>
          }
        >
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/school/dashboard" />} />
              <Route path="/*" element={<AuthRoutes />} />
              <Route path="/school/*" element={<SchoolRoute />} />
            </Routes>
            <Toaster />
          </Router>
        </Suspense>
      </QueryClientProvider>
    </div>
  );
}

export default App;
