import { useQuery } from "react-query";
import { instance } from "../../middleware/instance";
import { parseJwt } from "../../utils/decodeToken";
import { fetchSchProfile } from "../school-profile/schoolProfile";

const token = window.localStorage.getItem("authToken");
const jwtArray: any = parseJwt(token);

export const getSchId = async () => {
  const res = await fetchSchProfile();
  if (res.status === 200) {
    const id = res?.data?.data?._id;
    return id;
  }
};

// fetches the school notification
export const fetchSchNotification = async (
  pageParam: number,
  module: string
) => {
  const id = await getSchId();
  return await instance({
    url: `${module}/school/${id ? id : jwtArray[1]}?page=${pageParam}&limit=10`,
    method: "GET",
  });
};

// custom hook to fetch school profile
export const useSchoolNotification = (page: any, module: string) => {
  const queryKey =
    module === "notifications"
      ? "school-notification"
      : "school-employee-notification";
  const { data, isLoading, isError, isFetching } = useQuery(
    [`${queryKey}`, page, module],
    () => fetchSchNotification(page, module)
  );
  const schoolNotification = data;
  const loading = isLoading;

  return { schoolNotification, loading, isError, isFetching };
};
